import {
  deleteCopyObject,
  deleteNewObject,
  deleteSystemFolder,
  getNewObjects,
  getRemovedObjects,
  readSystemFile,
  readSystemFilesInFolder,
  writeOriginObject,
  writeSystemFile
} from '@/components/utils/mobile/filesystemInteraction'

export default {
  watch: {
    currentDownloading: {
      async handler () {
        if (this.currentDownloading === this.item.properties.id) {
          this.isLoading = true
          this.isWaiting = false
          await this.downloadPassport()
        } else {
          this.isLoading = false
        }
      },
      immediate: true,
      deep: true
    },
    currentSync: {
      async handler () {
        if (this.currentSync === this.item.properties.id) {
          this.isWaiting = false
          await this.syncData()
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    currentDownloading () {
      return this.$store.getters['queueDownload/currentDownloading']
    },
    currentSync () {
      return this.$store.getters['queueDownload/currentSync']
    }
  },
  methods: {
    preDownloading () {
      this.isWaiting = true
      this.$store.commit('queueDownload/addInQueue', { type: 'Downloading', id: this.item.properties.id })
    },
    preSync () {
      this.isWaiting = true
      this.$store.commit('queueDownload/addInQueue', { type: 'Sync', id: this.item.properties.id })
    },
    async save (data) {
      //note: отсюда я отдаю объект и в нем есть поле objectImages. Здесь оно имеет иной вид (отличный от сервера), но сервер на это поле не реагирует, что в данном случае хорошо. Оставляю эту подпись на случай, если потом что-то сломается
      const response = await this.$store.dispatch('server/save', {
        url: 'object/detail',
        data: { data }
      })
      if (this.isAllowSaveInSystem && response) { // обновляем файлы на устройстве
        await writeOriginObject(this.item.properties.id, data.properties.id, response) //добавляем ориджн
        const newObjects = await getNewObjects(this.item.properties.id)
        const findObject = newObjects
          ?.find((item) => item?.properties?.id === data.properties.id)
        if (await readSystemFile(this.pathOfDetailPassport + '/objects/copies/' + data.properties.id)) {//прочесть копию
          await deleteCopyObject(this.item.properties.id, data.properties.id) //удалить копию
        } else if (findObject) { //если есть новые объекты
          await this.loadImages(data.properties.objectImages, response) // синхронизация новых фото
          await deleteNewObject(data.properties.passport_id, data) // удалить тот, который загрузили на сервер
        }
      }
      return true
    },
    async syncData () {
      this.isLoading = true
      await this.syncCopies() //СИНХРОНИЗАЦИЯ КОПИЙ
      if (!this.isAllowSaveInSystem) { //если не оставляем файлы на устройстве
        await this.syncRemovedFolder()//СИНХРОНИЗАЦИЯ УДАЛЕННОЙ ПАПКИ (СЕРВЕРНЫЕ ОБЪЕКТЫ). софт делит
        await this.syncNewFolder() //новые (нарисованные) объекты
        await this.syncPhotos() //синхронизация фото только после папки new!
        await this.deletePassport()
      } else {
        await this.syncRemovedFolder() //софт делит объектов
        await this.syncNewFolder() //новые (нарисованные) объекты
        await this.syncPhotos() //синхронизация фото только после папки new!
        await this.writeModelPassport() //обновляю модели после того, как отправлены все объекты
        await this.updateOriginFiles() //обновление ориджн файлов после модели
      }
      this.isLoading = false
      this.$store.commit('queueDownload/finishSync')
    },
    async syncCopies () { //измененные файлы, т.е. копии
      const pathObjects = 'map/passport/' + this.item.properties.id + '/objects/copies/'
      const copiesObjects = await readSystemFilesInFolder(pathObjects)
      if (copiesObjects) {
        await Promise.all(copiesObjects.files.map(async (item) => {
          if (await readSystemFile(pathObjects + item.name)) {
            const fileObject = JSON.parse(await readSystemFile(pathObjects + item.name)) //
            await this.save(fileObject) // запрос на сохранение
          }
        }))
      }
    },
    async syncPhotos () {
      const pathCopyPhoto = 'map/passport/' + this.item.properties.id + '/objects/copies/images/'
      const photos = await readSystemFilesInFolder(pathCopyPhoto)
      if (photos && photos?.files) {
        await Promise.all(photos.files.map(async item => {
          const fileImages = JSON.parse(await readSystemFile('map/passport/' + this.item.properties.id + '/objects/copies/images/' + item.name)) //вернется массив с картинками
          await this.loadImages(fileImages, item)
          return true
        }))
        await deleteSystemFolder('map/passport/' + this.item.properties.id + '/objects/copies/images') // удалить папку картинок
      }
    },
    async loadImages (images, item) {
      const modelId = item?.name ? String(item?.name) : item?.properties.id
      const formData = new FormData()
      const descriptions = []
      if (images.length) {
        for (let i = 0; i < images.length; i++) {
          const blob = this.base64toBlob(images[i].image.path)
          const file = new File([blob], images[i].image.file_name || 'нет названия', { type: blob.type })
          formData.append(`files[${i}]`, file)
          descriptions.push({ //массив с описаниями. Тот же  порядок, что и порядок загрузки картинок. чтобы потом отдельно грузить описание
            description: images[i].image.description,
            description_date: new Date().toLocaleString('ru-RU', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          })
        }
        formData.append('modelName', 'object')
        formData.append('modelId', modelId)
        const response = await this.$store.dispatch('api/post', { url: '/image', data: formData }, { root: true })
        //после поста картинки грузится её описание
        if (response.success) {
          await Promise.all(response.data.map(async (res) => {
            if (!!descriptions[0]?.description) { //если описания нет
              const copy = res.image
              copy.description = descriptions[0].description // описание
              copy.description_date = descriptions[0].description_date // дата
              descriptions.splice(0, 1)
              await this.$store.dispatch('api/post', {
                url: '/image',
                data: { data: copy }
              }, { root: true })
            } else { //то его нужно из массива удалить, чтобы при следующей картинки не шло к нему обращения
              descriptions.splice(0, 1)
            }
          }))
        }
      }
    },
    base64toBlob (base64String) {
      const parts = base64String.split(';base64,')
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const rawLength = raw.length
      const uint8Array = new Uint8Array(new ArrayBuffer(rawLength))
      for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uint8Array], { type: contentType })
    },
    async syncNewFolder () {
      const newObjects = await getNewObjects(this.item.properties.id)
      if (newObjects?.length) {
        await Promise.all(newObjects.map(async (item) => {
          const CopyItem = item
          CopyItem.properties.objectImage = [] //отдаю массив с фото пустым
          await this.save(CopyItem) // запрос на сохранение
        }))
      }
    },
    async syncRemovedFolder () { //синхронизация папки removed
      const response = await getRemovedObjects(this.item.properties.id)
      if (response) {
        await Promise.all(response.files.map(async item => {
          const idObject = item.name
          await this.$store.dispatch('server/delete', {
            url: 'object/detail/' + idObject,
            data: { hard: 0 }
          })
        }))
      }

      const pathRemove = 'map/passport/' + this.item.properties.id + '/objects/removed'
      await deleteSystemFolder(pathRemove)
    },
    async updateOriginFiles () {
      //наболее актуальные данные находятся в модели, поэтому здесь идет сравнение с нею
      const models = JSON.parse(await readSystemFile(this.pathOfModelsPassport))
      const objects = await readSystemFilesInFolder(this.pathOfObjects)
      const listTypes = [{ 'MultiPolygon': 'мультиполигоны' }, { 'Polygon': 'полигоны' }, { 'LineString': 'линии' }, { 'Point': 'точки' }]
      listTypes.map(item => {
        const key = Object.keys(item)[0] // ключ по типу Multipolygon...
        if (models?.models[key]) { //если в модели есть указанный ключ
          for (const eavId in models?.models[key]) { //перебор объектов по типу(насаждение, и др) в точках, полигонах и т.д.
            models?.models[key][eavId].map(async (objectFromModel) => {
              const found = objects.files.find((element) => +element.name === +objectFromModel.properties.id) //поиск объекта из модели в ориджн
              if (!found) { //если его нет, значит добавить
                const getDetailObject = await this.$store.dispatch('server/get', {
                  url: `object/detail/${objectFromModel.properties.id}`
                })
                const clearedItem = await this.clearFiles(getDetailObject, 'object')
                await writeSystemFile(this.pathOfObjects + '/' + clearedItem.properties.id, clearedItem)
              }
            })
          }
        }
      })
    }
  }
}