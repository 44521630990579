<template>
  <v-list-item
    class="outlined wrapper-listItem"
    :style="isProcess ? {'background-image': `linear-gradient(90deg, #86FFAF ${currentPercent === 10 ? 2.24 : 8.24}%, #86FFAF ${percentSecondColor[currentPercent]}%, rgba(134, 255, 175, 0.00) ${currentPercent}%)`, 'background-color': '#FFF'} : {}"
    dense
    @click="$emit('select', { data: item, type: 'passport', selected: false })"
    :title="item.label"
    light
  >
    <v-list-item-content>
      <v-list-item-title class="black--text">{{ item.properties.object_name }}</v-list-item-title>
      <v-list-item-subtitle>{{ formatValue('passport.type', Number(item.properties.type)) }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ item.properties.territorial_affiliation }}</v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="isProcess"
        class="green--text"
      >идет загрузка {{ currentProc }} / {{ allObjects }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="blue--text"
        v-if="isProcessTiles && isLoading"
      >
        {{ currentProc === allObjects ? 'Сохраняем...' : 'Нарезаем... ' + currentProc + '/' + allObjects }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="isWaiting"
        class="orange--text"
      >Ожидание
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-row align-center">
      <v-icon
        size="24"
        color="orange"
        v-if="isWaiting"
      >mdi-progress-clock
      </v-icon>
      <BaseBtn
        v-if="isDownloaded && !isLoading && !isWaiting"
        size="30"
        icon="mdi-cached"
        is-type-icon
        color-icon="green"
        :ripple="false"
        @click="isDialogQuestionPassportInSystem = true"
      />
      <BaseBtn
        v-if="!isLoading && !isDownloaded && !isWaiting"
        size="32"
        icon="mdi-download-circle-outline"
        is-type-icon
        color-icon="black"
        :ripple="false"
        @click="preDownloading"
      />
      <BaseBtn
        v-if="!isLoading && isDownloaded && !isWaiting"
        size="32"
        icon="mdi-delete-circle-outline"
        is-type-icon
        color-icon="error"
        :ripple="false"
        @click="isDeleteDialog = true"
      />
      <BaseDialog
        :max-width="240"
        v-model="isDialogQuestionPassportInSystem"
        label="Синхронизация (отправка на сервер)"
        with-label
      >
        <template #content>
          <p class="pl-1">Оставить не синхронизированные файлы на устройстве? <span class="green--text">рекомендуется оставить</span>
          </p>
          <div class="d-flex justify-space-between pl-3 pr-3">
            <BaseBtn
              depressed
              @click="questionSaveInSystem(true)"
              title="да"
              color="primary"
            />
            <BaseBtn
              depressed
              @click="questionSaveInSystem(false)"
              title="нет"
              color="error"
            />
          </div>
        </template>
      </BaseDialog>
      <BaseDialog
        v-model="isDeleteDialog"
        role="delete"
        is-confirm-btn
        is-cancel-btn
        @confirm="deletePassport"
        :label="'Удалить загруженный паспорт с устройства(и его объекты)? ' + size + typeMemory"
        with-label
      />
      <v-progress-circular
        :size="24"
        color="primary"
        v-show="isLoading"
        indeterminate
      ></v-progress-circular>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import BaseBtn from '@/components/base/UI/BaseBtn'
import {
  deleteSystemFolder,
  readSystemFile,
  readSystemFilesInFolder,
  writeSystemFile
} from '@/components/utils/mobile/filesystemInteraction'
import { cleanClone, verificationScenarioByRole } from '@/components/utils/common'
import user from '@/components/mixins/user'
import BaseDialog from '@/components/base/mobile/BaseDialog'
import store from '@/store'
import sync from './sync'
import _ from 'lodash'
import { baseUrl } from '@/config/common'
import { openDB } from 'idb'

export default {
  name: 'ListItem',
  components: { BaseDialog, BaseBtn },
  mixins: [formatters, user, sync],
  props: {
    item: Object
  },
  data () {
    return {
      isDownloaded: false,
      isLoading: false,
      isDeleteDialog: false,
      isProcess: false,
      isProcessTiles: false,
      currentProc: 0,
      allObjects: 0,
      modelsPassport: [],
      size: 0,
      typeMemory: 'Кб',
      isAllowSaveInSystem: false,
      isDialogQuestionPassportInSystem: false,
      isWaiting: false,
      currentPercent: 0
    }
  },
  watch: {
    isDeleteDialog: {
      async handler () {
        if (this.isDeleteDialog) {
          const findObjects = await readSystemFilesInFolder(this.pathOfObjects) //origin
          const findCopies = await readSystemFilesInFolder('map/passport/' + this.item.properties.id + '/objects/copies/')
          const findImages = await readSystemFilesInFolder('map/passport/' + this.item.properties.id + '/objects/copies/images/')
          const findRemoved = await readSystemFilesInFolder('map/passport/' + this.item.properties.id + '/objects/removed/')
          let size = 0
          findObjects.files?.map((item) => {
            size += item.size
          })
          findCopies.files?.map((item) => {
            size += item.size
          })
          findImages.files?.map((item) => {
            size += item.size
          })
          findRemoved.files?.map((item) => {
            size += item.size
          })
          this.size = (size / 1024).toFixed(1)
          if (this.size >= 1024) {
            this.size = (this.size / 1024).toFixed(3)
            this.typeMemory = 'Мб'
          }
        }
      }
    },
    tilesProcess: {
      handler () {
        this.isProcessTiles = !(this.tilesProcess === 0 && this.tilesCount === 0)
        this.currentProc = this.tilesProcess
        this.allObjects = this.tilesCount
      }
    }
  },
  computed: {
    tilesProcess () {
      return this.$store.getters['queueDownload/currentTiles'].process
    },
    tilesCount () {
      return this.$store.getters['queueDownload/currentTiles'].count
    },
    pathOfDetailPassport () {
      return 'map/passport/' + this.item.properties.id
    },
    pathOfObjects () {
      return this.pathOfDetailPassport + '/objects'
    },
    pathOfModelsPassport () {
      return this.pathOfDetailPassport + '/models/' + this.item.properties.id
    },
    tenPercent () {
      return this.allObjects / 100 * 10
    },
    percentSecondColor () {
      return {
        10: 0.01,
        20: 3.65,
        30: 7.65,
        40: 11.65,
        50: 18.65,
        60: 42.65,
        70: 51.65,
        80: 55.65,
        90: 70.65,
        100: 100
      }
    }
  },
  methods: {
    makePercent () {
      let currentPercent = this.tenPercent
      let currentPercentByTen = 0
      do {
        currentPercent += this.tenPercent
        currentPercentByTen += 10
      } while (this.currentProc >= currentPercent)
      this.currentPercent = currentPercentByTen
    },
    questionSaveInSystem (isSave) {
      this.isAllowSaveInSystem = isSave
      this.isDialogQuestionPassportInSystem = false
      this.preSync()
    },
    async clearFiles (item, type) {
      const copyItem = cleanClone(item)
      if (type === 'object' && copyItem.properties[type + 'Images'].length) {
        const firstImage = copyItem.properties[type + 'Images'][0].image
        const src = `${baseUrl}images/${firstImage.path}thumbnails/${firstImage.file_name}`

        let dbPromise //создаю бд
        function openDataBaseSrc (passportIndex) {
          if (dbPromise) {
            return dbPromise
          }

          dbPromise = openDB(passportIndex, 2, {
            upgrade (db, oldVersion) {
              if (oldVersion < 1) {
                const tileStore = db.createObjectStore('srcStore', {
                  keyPath: 'id' //ключ
                })
                tileStore.createIndex(passportIndex, 'src')
              }
            }
          })
          return dbPromise
        }

        const db = await openDataBaseSrc('object') //открыть

        if (await db) {
          const image = {
            id: copyItem.properties.id,
            src
          }
          let transaction = db.transaction('srcStore', 'readwrite') // начать транзакцию
          let images = transaction.objectStore('srcStore')
          images.add(image) //сохранить картинку
        }
        setTimeout(() => {
          const image = new Image() //это запрос на картинку. делаю его, чтобы отловить в service-worker
          image.src = src
        }, 0)

        copyItem.properties[type + 'Images'] = [copyItem.properties[type + 'Images'][0]]
      } else {
        copyItem.properties[type + 'Images'] = []
        copyItem.properties[type + 'Files'] = []
      }
      return copyItem
    },
    async deletePassport () {
      let dbPromise

      function openDataBaseSrc () {
        if (dbPromise) {
          return dbPromise
        }
        dbPromise = indexedDB.open('object') //открыть бд
        return dbPromise
      }

      let allObject = []
      const copiesObjects = await readSystemFilesInFolder('map/passport/' + this.item.properties.id + '/objects' + '/copies')
      if (copiesObjects) {
        allObject = copiesObjects?.files.concat(allObject)
      }

      const originObjects = await readSystemFilesInFolder('map/passport/' + this.item.properties.id + '/objects/')
      if (originObjects) {
        allObject = allObject.concat(originObjects?.files)
      }

      const removedObject = await readSystemFilesInFolder('map/passport/' + this.item.properties.id + '/objects/' + 'removed')
      if (removedObject) {
        allObject = allObject.concat(removedObject?.files)
      }

      allObject.filter(item => item.type === 'file') //оставляю только файлы, чтобы не попали картинки и директории

      if (allObject.length) {
        await Promise.all(allObject.map(async (item) => {
          const itemPath = item.uri.replace('/DATA/', '')
          if (await readSystemFile(itemPath)) {
            const fileObject = JSON.parse(await readSystemFile(itemPath)) //
            const images = fileObject.properties.objectImages
            if (images.length) {
              await Promise.all(images.map(async (img) => {
                if (!img.isSystem) { //если не загруженное в оффлайне фото
                  dbPromise = null
                  const db = await openDataBaseSrc()
                  db.onsuccess = function (event) {
                    caches.open('images').then((cache) => {
                      cache.delete(`${baseUrl}images/${img.image.path}thumbnails/${img.image.file_name}`)
                    })
                    const objectId = img.image.path.split('/')[1]
                    const res = event.target.result
                    const transaction = res.transaction('srcStore', 'readwrite')
                    const objectStore = transaction.objectStore('srcStore')
                    // Получаем значение из хранилища
                    const getRequest = objectStore.get(+objectId) //получить src картинки из бд по fetch запросу
                    getRequest.onsuccess = function () {
                      objectStore.delete(+objectId)
                    }
                    return true
                  }
                }
                return true
              }))
            }
          }
          return true
        }))
      }
      this.$emit('deleteTilesPassport', this.item)
      await deleteSystemFolder(this.pathOfDetailPassport)
      await this.$emit('deletePassport', this.item)
      await this.checkCache()
    },
    async writeModelPassport () {
      this.modelsPassport = await this.$store.dispatch('server/get', {
        url: `object/map?filter[passport_id]=${this.item.properties.id}&filter[verificationScenario]=${verificationScenarioByRole()}`
      })
      if (Array.isArray(this.modelsPassport.models) && !this.modelsPassport.models.length) {
        this.$set(this.modelsPassport, 'models', {})
      }
      await writeSystemFile(this.pathOfModelsPassport, this.modelsPassport) //модели
    },
    async downloadPassport () {
      this.isLoading = true
      const detailPassport = await this.$store.dispatch('server/get', {
        url: '/passport/detail/' + this.item.properties.id,
        params: { additionalParams: { organization_id: this.$store.getters['auth/getProfile'].organization.id } }
      })
      const clearedItem = await this.clearFiles(this.item, 'passport')

      await this.writeModelPassport()
      await writeSystemFile(this.pathOfDetailPassport + '/detail', detailPassport) //детали
      await this.downloadAllObjects().then(() => store.dispatch('systemMessages/success', { text: 'Загружено' }))
      this.isProcess = false
      this.$emit('savePassport', clearedItem) // общая инф паспорта
      this.$emit('loadTiles', detailPassport)
      this.isDownloaded = true
    },
    async checkCache () {
      const findPassport = await readSystemFile(this.pathOfDetailPassport + '/detail')
      this.isDownloaded = !!findPassport
    },
    async countAllObjects (object) {
      for (let key in object) {
        this.allObjects += _.flattenDeep(Object.values(object[key])).length
      }
      return true
    },
    async downloadAllObjects () {
      this.isProcess = true
      this.currentProc = 0
      this.allObjects = 0
      await this.countAllObjects(this.modelsPassport.models)
      for (let typeGeo in this.modelsPassport.models) {//typeGeo = point,polygon...
        for (let keyType in this.modelsPassport.models[typeGeo]) {//keyType = 43,32,...
          for (const item of this.modelsPassport.models[typeGeo][keyType]) {// item = {type, properties, geometry}
            const getDetailObject = await this.$store.dispatch('server/get', {
              url: `object/detail/${item.properties.id}`,
              params: { additionalParams: { organization_id: this.$store.getters['auth/getProfile'].organization.id } }
            })
            if (getDetailObject) {
              const clearedItem = await this.clearFiles(getDetailObject, 'object')
              await writeSystemFile(this.pathOfObjects + '/' + clearedItem.properties.id, clearedItem)
            }
            this.currentProc += 1
            this.makePercent()
          }
        }
      }
      this.currentPercent = 0
    }
  },
  async mounted () {
    await this.checkCache()
  }
}
</script>

<style scoped>
  .outlined {
    border: 1px solid #F5F5F5;
    border-radius: 8px;
  }
  .wrapper-listItem {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.10);
    transition: background-color 1s;
  }
</style>